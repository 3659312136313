<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <div class="form-group">
            <label>Codigo (*):</label>
            <input type="number" class="form-control" v-model="item.rgm_cdgo" required>
          </div>
          <div class="form-group">
            <label>Descripción (opcional):</label>
            <input type="text" class="form-control" v-model="item.rgm_descripcion">
          </div>
          <label><b style="color:darkblue">AGREGAR CONDICIÓN</b></label>
          <button type="button" class="style-conditions-btn" @click="openModal"><i class="fa fa-plus"></i>Agregar</button>
          <!-- Tabla para mostrar las condiciones agregadas -->
          <table class="style-conditions-table">
            <thead>
              <tr>
                <th>Criterio</th>
                <th>Operador</th>
                <th>Valor</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(condicion, index) in condiciones" :key="index">
                <td>{{ getCampoNombre(condicion.campo) }}</td>
                <td>{{ getOperadorNombre(condicion.operador) }}</td>
                <td>{{ condicion.valor }}</td>
                <td>
                  <button type="button" class="style-conditions-delete" @click="eliminarCondicion(index)">X</button>
                </td>
              </tr>
            </tbody>
          </table> <br><br>
          <div class="form-group">
            <label class="custom-checkbox">
              ¿Radicar automáticamente?
              <input type="checkbox" id="rgm_radicar" v-model="item.rgm_radicar">
              <span></span> 
            </label>
          </div>
          <div v-if="errorMessage" class="alert alert-danger mt-2">{{ errorMessage }}</div>
          <br>
          <div v-if="item.rgm_radicar">
          <label><b style="color:darkblue">VALORES PREDETERMINADOS PARA CLASIFICACIÓN</b></label>
            <div class="form-group">
              <label>Clase de documento (*):</label>
              <v-select
                id="tcr_id"
                v-model="item.tcr_id"
                :items="tpocors"
                item-text="text"
                item-value="id"
                required
                filterable
                clearable
                :menu-props="{ offsetY: true }"
              />
            </div>
            <div class="form-group">
              <label>Tipo de documento (*):</label>
              <v-select
                id="tdo_id"
                v-model="item.tdo_id"
                :items="tpodocs"
                item-text="text"
                item-value="id"
                required
                filterable
                clearable
                :menu-props="{ offsetY: true }"
              />
            </div>
            <div class="form-row">
              <div class="form-group col-md-8">
                <label>Tipo de tabla (*):</label>
                <v-select
                  id="tta_id"
                  v-model="item.tta_id"
                  :items="tpotablas"
                  item-text="text"
                  item-value="id"
                  required
                  filterable
                  clearable
                  :menu-props="{ offsetY: true }"
                />
              </div>
              <div class="form-group col-md-4">
                <label>Versión de tabla (*):</label>
                <input id="tav_nmro" type="number" v-model="item.tav_nmro" class="form-control">
              </div>
            </div>
            <div class="form-group" style="position: relative;">
              <label>Dependencia (*):</label>
              <v-select
                id="dep_id"
                v-model="item.dep_id"
                :items="dependencias"
                item-text="text"
                item-value="id"
                required
                filterable
                clearable
                :menu-props="{ offsetY: true }"
              />
            </div>
            <div class="form-group">
              <label>Serie (*):</label>
              <v-select
                id="ser_id"
                v-model="item.ser_id"
                :items="series"
                item-text="text"
                item-value="id"
                required
                filterable
                clearable
                :menu-props="{ offsetY: true }"
              />
            </div>
            <div class="form-group">
              <label>Tipo de archivo (*):</label>
              <v-select
                id="tpa_id"
                v-model="item.tpa_id"
                :items="tpoarchs"
                item-text="text"
                item-value="id"
                required
                filterable
                clearable
                :menu-props="{ offsetY: true }"
              />
            </div>
            <div class="form-group">
              <label>Usuario (*):</label>
              <v-select
                id="usu_id"
                v-model="item.usu_id"
                :items="usuarios"
                item-text="text"
                item-value="id"
                required
                filterable
                clearable
                :menu-props="{ offsetY: true }"
              />
            </div>
            <div class="form-group">
              <label>Tiempo de respuesta (*):</label>
              <input type="number" v-model="item.dcm_tres" class="form-control">
            </div>
            <div class="form-group">
              <label class="custom-checkbox">
                ¿Activar pendiente de respuesta?
                <input type="checkbox" id="dcm_rspsta" v-model="item.dcm_rspsta">
                <span></span>
              </label>
            </div>
          </div>
          <br/>
          <br/>
          <div class="row" align="center">
            <div class="col-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
        <!-- Modal flotante -->
        <div v-if="showModal" class="style-conditions-modal">
          <div class="style-conditions-modal-content">
            <span class="style-conditions-close" @click="closeModal">&times;</span>
            <h2>Agregar Condición</h2>

            <!-- Formulario -->
            <div class="style-conditions-form">
              <label for="campo">Seleccione el atributo del mensaje (*)</label>
              <select id="campo" v-model="nuevaCondicion.campo">
                <option value="" disabled>Seleccione una opción</option>
                <option value="from">Remitente (De)</option>
                <option value="subject">Asunto</option>
                <option value="text">Texto del mensaje</option>
              </select>

              <label for="operador">Seleccione el operador de comparación (*)</label>
              <select id="operador" v-model="nuevaCondicion.operador">
                <option value="" disabled>Seleccione una opción</option>
                <option value="equals">Igual a</option>
                <option value="contains">Contiene</option>
              </select>

              <label for="valor">Texto a comparar (*)</label>
              <input type="text" id="valor" v-model="nuevaCondicion.valor" />

              <button class="style-conditions-btn" @click="agregarCondicion">Agregar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import toastr from 'toastr';

export default {
  data() {
    return {
      add: true, //Create a new document
      close: true, // Close the page and return to list
      title: "",
      item: {
        condiciones: [],
      },
      message: "",
      buttontext: "",
      fecha: null,
      search: {},
      dependencias: [],
      series: [],
      showModal: false,
      nuevaCondicion: {
        campo: "",
        operador: "",
        valor: ""
      },
      condiciones: [],
      tpocors: [],
      tpodocs: [],
      tpotablas: [],
      tpoarchs: [],
      usuarios: [],
      errorMessage: "",
    };
  },
  computed: {
    company() {
      return this.$store.state.company;
    },
    user() {
      return this.$store.state.user;
    },
    cancelButtonVisibility() {
      if (this.addInd) {
        return "visibility:hidden";
      } else {
        return "visibility:visible";
      }
    },
  },
  created: function() {
    this.search = this.$route.params.search;
    this.add = this.$route.params.add;
    if (this.add) {
      this.title = "Crear regla para radicación de correo electrónico";
      this.buttontext = "Agregar";
    } else {
      this.title = "Editar Regla";
      this.buttontext = "Actualizar";
      this.getItem(this.$route.params.id);
    }
    this.fetchDependencia();
    this.fetchSerie();
    this.fetchTpocor();
    this.fetchTpodoc();
    this.fetchTpotabla();
    this.fetchTpoarch();
    this.fetchUsuario();
  },
  methods: {
    getCampoNombre(campo) {
      return campo === 'from' ? 'Remitente (De)' :
            campo === 'subject' ? 'Asunto' :
            campo === 'text' ? 'Texto del mensaje' : campo;
    },
    getOperadorNombre(operador) {
      return operador === 'equals' ? 'Igual a' :
            operador === 'contains' ? 'Contiene' : operador;
    },
    customFilter(item, queryText) {
      const text = item.text.toLowerCase();
      const query = queryText.toLowerCase();
      return text.includes(query); 
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    agregarCondicion() {
      const { campo, operador, valor } = this.nuevaCondicion;

      // Validar que los campos no estén vacíos ni contengan solo espacios
      if (!campo.trim() || !operador.trim() || !valor.trim()) {
        alert("Todos los campos son obligatorios y no pueden estar vacíos.");
        return;
      }
      // Agregar la condición al array
      this.condiciones.push({ campo, operador, valor });
      // Limpiar el formulario
      this.nuevaCondicion = { campo: "", operador: "", valor: "" };
      this.closeModal();
    },
    eliminarCondicion(index) {
      this.condiciones.splice(index, 1);
    },
    setClose(close) {
      this.close = close;
    },
    saveItem() {
      if (this.add) {
        this.item.cmp_id = String(this.$store.state.company);
        let uri = "/reglaemails/add";
        //alert(this.item);
        this.item.condiciones = this.condiciones;
        if (!this.item.rgm_radicar) {
          delete this.item.tcr_id;
          delete this.item.tdo_id;
          delete this.item.tta_id;
          delete this.item.tav_nmro;
          delete this.item.dep_id;
          delete this.item.ser_id;
          delete this.item.tpa_id;
          delete this.item.usu_id;
          delete this.item.dcm_tres;
          delete this.item.dcm_rspsta;
        }
        console.log('Este es el item a enviar:', this.item)
        this.axios
          .post(uri, this.item)
          .then((response) => {
            console.log(response);
            this.$router.replace({ name: "DisplayReglaEmail", params: { search: this.search } });
            this.message = "Registro guardado";
            this.errorMessage = "";
          })
          .catch((err) => {
            console.log(err);
            if (err.response && err.response.status === 400) {
              this.errorMessage = "¡Error al actualizar el registro! " + err.response.data.message;
            } if (err.response && err.response.status === 409) {
              this.errorMessage =  err.response.data.message;
            } else {
              this.errorMessage = "¡Error al actualizar el registro! " + err.message;
            }
          });
      } else {
        let uri = "/reglaemails/update/" + this.item._id;
        this.axios.post(uri, this.item).then((response) => {
          if (this.close) {
            this.$router.replace({ name: "DisplayReglaEmail", params: { search: this.search } });
          }
          this.message = "Registro guardado";
        });
      }
    },
    getItem(id) {
      let uri = "/reglaemails/edit/" + id;
      this.axios.get(uri).then((response) => {
        this.item = response.data;
        this.fecha = this.item.dnl_fecha.toString().substring(0,10);
      });
    },
    cancel() {
      this.$router.replace({ name: "DisplayReglaEmail", params: { search: this.search } });
    },
    fetchDependencia(){
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.dep_nmbre + ' (' + a.dep_cdgo + ')').toLowerCase(); 
          var tb = String(b.dep_nmbre + ' (' + b.dep_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.dependencias = list.map(i => ({ id: String(i._id), text: i.dep_nmbre + ' (' + i.dep_cdgo + ')' }));
      });
    },
    fetchSerie(){
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.ser_nmbre + ' (' + a.ser_cdgo + ')').toLowerCase(); 
          var tb = String(b.ser_nmbre + ' (' + b.ser_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.series = list.map(i => ({ id: String(i._id), text: i.ser_nmbre + ' (' + i.ser_cdgo + ')' }));
      });
    },
    fetchTpocor(){
      let uri = '/tpocors/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.tcr_nmbre + ' (' + a.tcr_cdgo + ')').toLowerCase(); 
          var tb = String(b.tcr_nmbre + ' (' + b.tcr_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.tpocors = list.map(i => ({ id: String(i._id), text: i.tcr_nmbre + ' (' + i.tcr_cdgo + ')' }));
      });
    },
    fetchTpodoc(){
      let uri = '/tpodocs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.tdo_nmbre + ' (' + a.tdo_cdgo + ')').toLowerCase(); 
          var tb = String(b.tdo_nmbre + ' (' + b.tdo_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.tpodocs = list.map(i => ({ id: String(i._id), text: i.tdo_nmbre + ' (' + i.tdo_cdgo + ')' }));
      });
    },
    fetchTpotabla(){
      let uri = '/tpotablas/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.tta_nmbre + ' (' + a.tta_cdgo + ')').toLowerCase(); 
          var tb = String(b.tta_nmbre + ' (' + b.tta_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.tpotablas = list.map(i => ({ id: String(i._id), text: i.tta_nmbre + ' (' + i.tta_cdgo + ')' }));
      });
    },
    fetchTpoarch(){
      let uri = '/tpoarchs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.tpa_nmbre + ' (' + a.tpa_cdgo + ')').toLowerCase(); 
          var tb = String(b.tpa_nmbre + ' (' + b.tpa_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.tpoarchs = list.map(i => ({ id: String(i._id), text: i.tpa_nmbre + ' (' + i.tpa_cdgo + ')' }));
      });
    },
    
    async fetchUsuario(){
      try {
        let uri = '/usuarios/list';
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        var response = await this.axios.post(uri, { cmp_id: String(this.$store.state.company) });        
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.usu_nmbre + ' ' + a.usu_aplldo + ' (' + a.usu_cdgo + ')').toLowerCase(); 
          var tb = String(b.usu_nmbre + ' ' + b.usu_aplldo + ' (' + b.usu_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.usuarios = list.map(i => ({ id: String(i._id), text: i.usu_nmbre + ' ' + i.usu_aplldo + ' (' + i.usu_cdgo + ')' }));      
      } catch(err) {
        console.log(err);
      }
    },
  },
};
</script>

  <style>
.warning {
  color: #ff0000;
}
/* Botón principal */
.style-conditions-btn {
  display: flex;
  align-items: center;
  gap: 8px; /* Espacio entre el ícono y el texto */
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.style-conditions-btn:hover {
  background-color: #0056b3;
}

/* Modal - fondo oscuro */
.style-conditions-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Contenido del modal */
.style-conditions-modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  position: relative;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Botón de cerrar modal */
.style-conditions-close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.style-conditions-close:hover {
  color: red;
}

/* Estilos del formulario */
.style-conditions-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.style-conditions-form label {
  font-weight: bold;
}

.style-conditions-form select,
.style-conditions-form input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Tabla de condiciones */
.style-conditions-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.style-conditions-table th,
.style-conditions-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.style-conditions-table th {
  background-color: #f4f4f4;
}

/* Botón de eliminar */
.style-conditions-delete {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.style-conditions-delete:hover {
  background-color: darkred;
}
.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 8px; 
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox span {
  width: 25px;
  height: 21px;
  border: 2px solid black;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox input[type="checkbox"]:checked + span::before {
  content: '✔';
  font-size: 16px;
  color: black;
}

</style>