import { render, staticRenderFns } from "./CreateReglaEmail.vue?vue&type=template&id=436f35e5"
import script from "./CreateReglaEmail.vue?vue&type=script&lang=js"
export * from "./CreateReglaEmail.vue?vue&type=script&lang=js"
import style0 from "./CreateReglaEmail.vue?vue&type=style&index=0&id=436f35e5&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
